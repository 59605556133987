.min-h-screen {
	min-height: var(--screenfull) !important;
}

.max-h-screen {
	max-height: var(--screenfull) !important;
}

.h-screen {
	height: var(--screenfull) !important;
}

.rainbow-text {
	background-image: linear-gradient(to left, violet, indigo, blue, green, yellow, orange, red);
  }

.custom-animation-pulse {
	animation: custom-pulse 3s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes custom-pulse {
	0%,
	100% {
		opacity: 1;
	}
	50% {
		opacity: 0.8;
	}
}

.custom-animation-phonesignup {
	animation: custom-phonesignup 12s cubic-bezier(0, 0, 1, 1) infinite;
}

@keyframes custom-phonesignup {
	40%,
	10% {
		transform: translateX(0%);
	}
	45%,
	95% {
		transform: translateX(-100%);
	}
}

.custom-animation-phoneselectstation {
	animation: custom-phoneselectstation 12s cubic-bezier(0, 0, 1, 1) infinite;
}

@keyframes custom-phoneselectstation {
	40%,
	10% {
		transform: translateX(100%);
	}
	45%,
	95% {
		transform: translateX(0%);
	}
}

.custom-animation-phonesignupemail {
	animation: custom-phonesignupemail 12s cubic-bezier(0, 0, 1, 1) infinite;
}

@keyframes custom-phonesignupemail {
	15% {
		width: 0%;
	}
	25% {
		width: 85%;
	}
	58% {
		width: 85%;
	}
	60% {
		width: 0%;
	}
}

.custom-animation-phonesignuppassword {
	animation: custom-phonesignuppassword 12s cubic-bezier(0, 0, 1, 1) infinite;
}

@keyframes custom-phonesignuppassword {
	20% {
		width: 0%;
	}
	30% {
		width: 55%;
	}
	58% {
		width: 55%;
	}
	60% {
		width: 0%;
	}
}

.custom-animation-phonesignupbutton {
	animation: custom-phonesignupbutton 12s cubic-bezier(0, 0, 1, 1) infinite;
}

@keyframes custom-phonesignupbutton {
	36% {
		background-color: rgb(71, 85, 105);
	}
	39% {
		background-color: rgb(0, 0, 0);
	}
	58% {
		background-color: rgb(0, 0, 0);
	}
	60% {
		background-color: rgb(71, 85, 105);
	}
}

.custom-animation-phonestation1gray {
	animation: custom-phonestation1gray 12s cubic-bezier(0, 0, 1, 1) infinite;
}

@keyframes custom-phonestation1gray {
	55% {
		opacity: 1;
	}
	65% {
		opacity: 0;
	}
	99% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.custom-animation-phonestation1green {
	animation: custom-phonestation1green 12s cubic-bezier(0, 0, 1, 1) infinite;
}

@keyframes custom-phonestation1green {
	0%,
	55%,
	100% {
		opacity: 0;
	}
	65%,
	99% {
		opacity: 1;
	}
}

.custom-animation-phonestation2gray {
	animation: custom-phonestation2gray 12s cubic-bezier(0, 0, 1, 1) infinite;
}

@keyframes custom-phonestation2gray {
	65% {
		opacity: 1;
	}
	75% {
		opacity: 0;
	}
	99% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.custom-animation-phonestation2green {
	animation: custom-phonestation2green 12s cubic-bezier(0, 0, 1, 1) infinite;
}

@keyframes custom-phonestation2green {
	0%,
	65%,
	100% {
		opacity: 0;
	}
	75%,
	99% {
		opacity: 1;
	}
}

.custom-animation-phonestation3gray {
	animation: custom-phonestation3gray 12s cubic-bezier(0, 0, 1, 1) infinite;
}

@keyframes custom-phonestation3gray {
	75% {
		opacity: 1;
	}
	85% {
		opacity: 0;
	}
	99% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.custom-animation-phonestation3green {
	animation: custom-phonestation3green 12s cubic-bezier(0, 0, 1, 1) infinite;
}

@keyframes custom-phonestation3green {
	0%,
	75%,
	100% {
		opacity: 0;
	}
	85%,
	99% {
		opacity: 1;
	}
}

.custom-animation-message1 {
	animation: custom-message1 20s cubic-bezier(0, 0, 1, 1) infinite;
}

@keyframes custom-message1 {
	100%,
	0%,
	13% {
		transform: translateX(-150%);
	}
	15%,
	99% {
		transform: translateX(0%);
	}
}

.custom-animation-message2 {
	animation: custom-message2 20s cubic-bezier(0, 0, 1, 1) infinite;
}

@keyframes custom-message2 {
	100%,
	0%,
	28% {
		transform: translateX(-150%);
	}
	30%,
	99% {
		transform: translateX(0%);
	}
}

.custom-animation-message3 {
	animation: custom-message3 20s cubic-bezier(0, 0, 1, 1) infinite;
}

@keyframes custom-message3 {
	100%,
	0%,
	43% {
		transform: translateX(-150%);
	}
	45%,
	99% {
		transform: translateX(0%);
	}
}

.custom-animation-message4 {
	animation: custom-message4 20s cubic-bezier(0, 0, 1, 1) infinite;
}

@keyframes custom-message4 {
	100%,
	0%,
	63% {
		transform: translateX(-150%);
	}
	65%,
	99% {
		transform: translateX(0%);
	}
}
